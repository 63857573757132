import { format, distanceInWords, differenceInDays } from 'date-fns'
import React from 'react'
import { buildImageObj } from '../lib/helpers'
import { imageUrlFor } from '../lib/image-url'
import PortableText from './portableText'
import Container from './container'
import AuthorList from './author-list'

// import styles from './blog-post.module.css'

function BlogPost(props) {
  const { _rawBody, authors, categories, title, mainImage, publishedAt } = props
  return (
    <article>
      <Container>
        <div>
          <div>
            <h1>{title}</h1>
            <aside>
              {publishedAt && (
                <div className='event-start-date'>
                  {format(new Date(publishedAt), 'MMMM Do, YYYY')}
                </div>
              )}
              {/* {authors && <AuthorList items={authors} title='Authors' />}
            {categories && (
              <div>
                <h3>Categories</h3>
                <ul>
                  {categories.map(category => (
                    <li key={category._id}>{category.title}</li>
                  ))}
                </ul>
              </div>
            )} */}
            </aside>
            {mainImage && mainImage.asset && (
              <div>
                <img
                  src={imageUrlFor(buildImageObj(mainImage))
                    // .width(1200)
                    // .height(Math.floor((9 / 16) * 1200))
                    // .fit('crop')
                    .auto('format')
                    .url()}
                  alt={mainImage.alt}
                />
              </div>
            )}
            {_rawBody && <PortableText blocks={_rawBody} />}
          </div>
        </div>
      </Container>
    </article>
  )
}

export default BlogPost
